function handleSearchScripts() {
  const highlightText = (haystack = '', needles = []) => {
    const accentMap = {
      ae: '(ae|æ|ǽ|ǣ)',
      a: '(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)',
      c: '(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)',
      e: '(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)',
      i: '(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)',
      n: '(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)',
      o: '(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)',
      u: '(u|ú|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)'
    }

    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    function checkHighlightList(str, queries) {
      const accentRegex = new RegExp(Object.keys(accentMap).join('|'), 'g')
      const queryRegex = new RegExp(
        queries
          .map((q) => {
            return escapeRegExp(q)
              .toLowerCase()
              .replace(accentRegex, (m) => {
                return accentMap[m] || m
              })
          })
          .join('|'),
        'gi'
      )
      return str.replace(queryRegex, (m) => `<strong>${m}</strong>`)
    }

    return checkHighlightList(haystack, needles)
  }

  // header Search Toggle

  const button = document.querySelector('#theme-header [data-js="search"]')
  const searchResults = document.querySelector('#theme-header [data-js="search-results"]')
  const searchResultsWrapper = document.querySelector('#theme-header [data-js="search-results-wrapper"]')
  const searchForm = document.querySelector('#theme-header [data-js="search-form"]')
  if (button && searchResults && searchResultsWrapper && searchForm) {
    document.addEventListener('search-open', () => {
      if (document.documentElement.classList.contains('menu-open')) {
        document.dispatchEvent(new Event('menu-close'))
      }

      document.documentElement.classList.add('search-open')
      searchForm.classList.add('is-open')
      searchForm.reset()

      button.dataset.open = true
    })

    document.addEventListener('search-close', () => {
      document.documentElement.classList.remove('search-open')
      searchResults.classList.remove('is-open')
      searchForm.classList.remove('is-open')
      searchForm.reset()
      searchResultsWrapper.innerHTML = ''

      button.removeAttribute('data-open')
    })

    // Toggle the search using the button
    button.addEventListener('click', (e) => {
      e.preventDefault()

      if (!button.dataset.open) {
        document.dispatchEvent(new Event('search-open'))
      } else {
        document.dispatchEvent(new Event('search-close'))
      }
    })

    // Close the search using the background
    searchResults.addEventListener('click', (e) => {
      if (e.target === e.currentTarget && searchResults.classList.contains('is-open')) {
        if (button.dataset.open) {
          document.dispatchEvent(new Event('search-close'))
        }
      }
    })

    // Close the search using escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        if (button.dataset.open) {
          document.dispatchEvent(new Event('search-close'))
        }
      }
    })
  }

  // errorSearchToggle

  // const searchResultsWrapper = document.querySelector('#content [data-js="search-results-wrapper"]');
  // const searchForm = document.querySelector('#content [data-js="search-form"]');

  if (searchResultsWrapper && searchForm) {
    document.addEventListener('error-search-open', () => {
      if (!searchResultsWrapper.classList.contains('is-open')) {
        searchResultsWrapper.classList.add('is-open')
      }
    })

    document.addEventListener('error-search-close', () => {
      if (searchResultsWrapper.classList.contains('is-open')) {
        searchResultsWrapper.classList.remove('is-open')
      }
    })

    // Open the search results on focus
    searchForm.addEventListener('click', () => {
      searchResults.classList.add('is-open')
      document.dispatchEvent(new Event('error-search-open'))
    })

    // Close the search results on click outside
    document.addEventListener('click', (e) => {
      const isSearchParts = e.target.closest('#content [data-js="search-results-wrapper"]') || e.target.closest('#content [data-js="search-form"]')
      if (!isSearchParts) {
        document.dispatchEvent(new Event('error-search-close'))
      }
    })

    // Close the search using escape key
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        document.dispatchEvent(new Event('error-search-close'))
      }
    })
  }

  const listPosts = (posts, searchValue, firstText) => {
    let html = /* html */ `
            <ul>
              <li>
                <a href="${window.location.origin}?s=${searchValue}">
                ${firstText} <q><strong>${searchValue}</strong></q>
                </a>
              </li>`

    if (posts.length) {
      posts.forEach((post) => {
        html += /* html */ `
              <li>
                <a href="${post.url}">
                ${highlightText(post.title, [searchValue])}
                </a>
              </li>`
      })
    }

    html += /* html */ '</ul>'

    return html
  }

  const noPostsFound = (notFoundText) => {
    return /* html */ `
          <ul>
            <li>
              <a>${notFoundText}</a>
            </li>
          </ul>`
  }

  // Search posts

  const wrappers = []

  const headerWrapper = document.querySelector('#theme-header')
  if (headerWrapper) {
    wrappers.push(headerWrapper)
  }

  const contentWrapper = document.getElementById('content')
  if (contentWrapper) {
    wrappers.push(contentWrapper)
  }

  if (wrappers.length) {
    wrappers.forEach((wrapper) => {
      const searchForm = wrapper.querySelector('[data-js="search-form"]')
      const searchResultsWrapper = wrapper.querySelector('[data-js="search-results-wrapper"]')
      if (searchForm && searchResultsWrapper) {
        const searchInput = searchForm.querySelector('input[type="search"]')
        if (searchInput) {
          const queryParams = {
            nonce: wp_params.rest_nonce, // eslint-disable-line
            // search_columns: 'post_title',
            per_page: 5
          }

          // Setup interval variables
          let typingTimer
          const doneTypingInterval = 250

          searchInput.addEventListener('input', () => {
            clearTimeout(typingTimer)
            typingTimer = setTimeout(() => {
              const value = searchInput.value
              if (value !== '' && value.length >= 3) {
                queryParams.search = value

                searchResultsWrapper.innerHTML
                  = '<ul class="waiting-search"><li><a>Procurar...</a><button class="tie-popup-search-submit" type="submit"><span class="tie-search-icon tie-icon-spinner" aria-hidden="true"></span></button></li></ul>'

                // Query posts with previously declared params
                fetch(`${wp_params.rest_url}/search?${new URLSearchParams(queryParams)}`) // eslint-disable-line
                  .then(async (response) => {
                    const posts = await response.json()
                    if (posts.length) {
                      // Append posts to html
                      const html = listPosts(posts, value, searchResultsWrapper.dataset.text)

                      searchResultsWrapper.innerHTML = ''

                      searchResultsWrapper.insertAdjacentHTML('afterbegin', html)
                    } else {
                      // Append no result found message to html
                      const html = noPostsFound(searchResultsWrapper.dataset.notfoundText)

                      searchResultsWrapper.innerHTML = ''

                      searchResultsWrapper.insertAdjacentHTML('afterbegin', html)
                    }
                  })
                  .catch((err) => {
                    console.error(`${err} - Ajax error search posts query`)
                  })
                  .finally(() => {
                    document.dispatchEvent(new Event('error-search-open'))
                  })
              } else {
                // Clear results area
                searchResultsWrapper.innerHTML = ''
              }
            }, doneTypingInterval)
          })
        }
      }
    })
  }
}

if (document.readyState !== 'loading') {
  handleSearchScripts()
} else {
  document.addEventListener('DOMContentLoaded', handleSearchScripts)
}
